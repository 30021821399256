.uploadBody{
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    background-color: var(--body-bg);
    color: var(--txt-color);

    display: flex;
    justify-content: center;
    padding-top: 100px;

}
.box {
    background-color: var(--box-bg);
    padding: 30px;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
}

.header {
    margin-bottom: 30px;
    text-align: center;
}

.drop-file-input {
    

    border: 2px dashed var(--border-color);
    border-radius: 20px;


    background-color: var(--input-bg);
}

.drop-file-input input {
    opacity: 0;
    /* position: absolute; */
    /* top: 0;
    left: 55px; */
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}

.drop-file-input__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--txt-second-color);
    font-weight: 600;
    padding: 10px;
}

.drop-file-input__label img {
    width: 25%;
}

.drop-file-preview {
    margin-top: 30px;
}

.drop-file-preview p {
    font-weight: 500;
}

.drop-file-preview__title {
    margin-bottom: 20px;
}

.drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    background-color: var(--input-bg);
    padding: 15px;
    border-radius: 20px;
}

.drop-file-preview__item img {
    width: 50px;
    margin-right: 20px;
}

.drop-file-preview__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.drop-file-preview__item__del {
    background-color: var(--box-bg);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    opacity: 0;
}
.drop-file-preview__item:hover{
    background-color: rgba(31, 13, 13, 0.634);
    color: white;
    transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 2;
}